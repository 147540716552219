function organizationSchemaData(schemaDataFromStoryblok) {
  let schemaData = null;
  let schemaScript = null;
  const contactPoints = [];
  const sameAs = [];
  let address = null;
  const founder = [];

  if (schemaDataFromStoryblok && schemaDataFromStoryblok.enableOrganizationSchema) {
    // Add Address
    if (schemaDataFromStoryblok.organizationAddress) {
      schemaDataFromStoryblok.organizationAddress.forEach((el) => {
        address = {
          '@type': 'PostalAddress',
          addressCountry: el.addressCountry,
          addressLocality: el.addressLocality,
          postalCode: el.postalCode,
          streetAddress: el.streetAddress,
        };
      });
    }

    // Add founders
    if (
      schemaDataFromStoryblok.organizationFounder &&
      schemaDataFromStoryblok.organizationFounder.length > 0
    ) {
      schemaDataFromStoryblok.organizationFounder.forEach((el) => {
        const founderSameAs = [];
        el.sameAs.forEach((founder) => {
          founderSameAs.push(founder.url);
        });

        founder.push({
          '@type': 'Person',
          name: el.name,
          gender: el.gender,
          image: el.image.filename,
          jobTitle: el.jobTitle,
          sameAs: founderSameAs,
        });
      });
    }

    // Add contactPoint
    if (
      schemaDataFromStoryblok.organizationContactPoint &&
      schemaDataFromStoryblok.organizationContactPoint.length > 0
    ) {
      schemaDataFromStoryblok.organizationContactPoint.forEach((el) => {
        contactPoints.push({
          '@type': 'ContactPoint',
          telephone: el.telephone,
          contactType: el.contactType,
          areaServed: el.areaServed,
          availableLanguage: el.availableLanguage,
        });
      });
    }
    // Add sameAs
    if (
      schemaDataFromStoryblok.organizationSameAs &&
      schemaDataFromStoryblok.organizationSameAs.length > 0
    ) {
      schemaDataFromStoryblok.organizationSameAs.forEach((el) => {
        sameAs.push(el.url);
      });
    }

    schemaData = {
      '@context': 'https://schema.org',
      '@type': schemaDataFromStoryblok.organizationType,
      name: schemaDataFromStoryblok.organizationName,
      url: schemaDataFromStoryblok.organizationUrl,
      email: schemaDataFromStoryblok.organizationEmail,
      description: schemaDataFromStoryblok.organizationDescription,
      logo: schemaDataFromStoryblok.organizationLogoUrl.filename,
      address: address,
      founder: founder,
      foundingDate: schemaDataFromStoryblok.organizationFoundingDate,
      contactPoint: contactPoints,
      sameAs: sameAs,
    };
  }

  if (schemaData) {
    schemaScript = {
      hid: 'schemaOrganization',
      innerHTML: JSON.stringify(schemaData),
      type: 'application/ld+json',
    };

    return schemaScript;
  }

  return null;
}

function faqPageSchemaData(schemaDataFromStoryblok) {
  let schemaData = null;
  let schemaScript = null;
  const mainEntity = [];

  if (
    schemaDataFromStoryblok &&
    schemaDataFromStoryblok.enableFaqPageSchema &&
    schemaDataFromStoryblok.faqPageQuestions &&
    schemaDataFromStoryblok.faqPageQuestions.length > 0
  ) {
    schemaDataFromStoryblok.faqPageQuestions.forEach((el) => {
      mainEntity.push({
        '@type': 'Question',
        name: el.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: el.answer,
        },
      });
    });

    schemaData = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: mainEntity,
    };
  }

  if (schemaData) {
    schemaScript = {
      hid: 'schemaFaqPage',
      innerHTML: JSON.stringify(schemaData),
      type: 'application/ld+json',
    };

    return schemaScript;
  }
}

function howToSchemaData(schemaDataFromStoryblok) {
  let schemaData = null;
  let schemaScript = null;
  const steps = [];

  if (
    schemaDataFromStoryblok &&
    schemaDataFromStoryblok.enableHowToSchema &&
    schemaDataFromStoryblok.howToSteps &&
    schemaDataFromStoryblok.howToSteps.length > 0
  ) {
    schemaDataFromStoryblok.howToSteps.forEach((el) => {
      steps.push({
        '@type': 'HowToStep',
        name: el.name,
        text: el.text,
        image: el.image.filename,
        url: el.url.cached_url,
      });
    });

    schemaData = {
      '@context': 'https://schema.org',
      '@type': 'HowTo',
      name: schemaDataFromStoryblok.howToName,
      description: schemaDataFromStoryblok.howToDescription,
      image: schemaDataFromStoryblok.howToImage.filename,
      step: steps,
    };
  }

  if (schemaData) {
    schemaScript = {
      hid: 'schemaHowTo',
      innerHTML: JSON.stringify(schemaData),
      type: 'application/ld+json',
    };

    return schemaScript;
  }
}

function reviewSchemaData(schemaDataFromStoryblok) {
  let schemaData = null;
  let schemaScript = null;
  const reviews = [];

  if (
    schemaDataFromStoryblok &&
    schemaDataFromStoryblok.enableReviewSchema &&
    schemaDataFromStoryblok.reviews &&
    schemaDataFromStoryblok.reviews.length > 0
  ) {
    schemaDataFromStoryblok.reviews.forEach((el) => {
      reviews.push({
        '@type': 'Review',
        name: el.reviewName,
        reviewBody: el.reviewBody,
        datePublished: el.datePublished,
      });
      if (el.reviewRatingValue) {
        reviews.push({
          reviewRating: {
            '@type': 'Rating',
            ratingValue: el.reviewRatingValue,
          },
        });
      }
      if (el.reviewAuthorName) {
        reviews.push({
          author: {
            '@type': 'Person',
            name: el.reviewAuthorName,
          },
        });
      }
      if (el.reviewPublisherName) {
        reviews.push({
          publisher: {
            '@type': 'Organization',
            name: el.reviewPublisherName,
          },
        });
      }
    });

    schemaData = {
      '@context': 'https://schema.org',
      '@type': 'Review',
      itemReviewed: {
        '@type': schemaDataFromStoryblok.reviewItemReviewedType,
        name: schemaDataFromStoryblok.reviewItemReviewedName,
        description: schemaDataFromStoryblok.reviewItemReviewedDescription,
      },
      review: reviews,
    };
  }
  if (schemaData) {
    schemaScript = {
      hid: 'schemaRating',
      innerHTML: JSON.stringify(schemaData),
      type: 'application/ld+json',
    };

    return schemaScript;
  }
}

export function buildPageSchemaFromStoryblok(globalOrganizationSchema, pageSchemas) {
  const schemas = [];
  const schemaListOfDangerouslyDisableSanitizersId = {};

  // Construct schema from Storyblok globals
  if (globalOrganizationSchema) {
    const schema = organizationSchemaData(globalOrganizationSchema);
    if (schema) {
      schemas.push(schema);
      schemaListOfDangerouslyDisableSanitizersId.schemaOrganization = ['innerHTML'];
    }
  }

  // Construct schemas
  if (pageSchemas) {
    if (pageSchemas.faqPageSchema?.[0]) {
      const schema = faqPageSchemaData(pageSchemas.faqPageSchema[0]);
      if (schema) {
        schemas.push(schema);
        schemaListOfDangerouslyDisableSanitizersId.schemaFaqPage = ['innerHTML'];
      }
    }

    if (pageSchemas.howToSchema?.[0]) {
      const schema = howToSchemaData(pageSchemas.howToSchema[0]);
      if (schema) {
        schemas.push(schema);
        schemaListOfDangerouslyDisableSanitizersId.schemaHowTo = ['innerHTML'];
      }
    }

    if (pageSchemas.reviewsSchema?.[0]) {
      const schema = reviewSchemaData(pageSchemas.reviewsSchema[0]);
      if (schema) {
        schemas.push(schema);
        schemaListOfDangerouslyDisableSanitizersId.schemaRating = ['innerHTML'];
      }
    }
  }

  return {
    schemas: schemas,
    schemaListOfDangerouslyDisableSanitizersId:
      schemaListOfDangerouslyDisableSanitizersId,
  };
}
